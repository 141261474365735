<template>
  <CreditCardTemplate
    :formFlag="formFlag"
    :hideUpload="hideUpload"
    :resultFlag="resultFlag"
    :successFlag="successFlag"
    :icon_show_success.sync="icon_show_success"
    :icon_show_fail="icon_show_fail"
    :resultMessage.sync="resultMessage"
    :userId="userId"
    :paymentDepositId.sync="paymentDepositId"
  >
    <div class="main-deposit" v-if="formFlag && !bridgerPayPayments.show && !loadTemplate">
      <div class="max">
        <el-steps direction="vertical">
          <el-step icon="red"></el-step>
          <el-step icon="blue"></el-step>
          <el-step icon="red"></el-step>
        </el-steps>
        <div class="depositFunds-box">
          <div class="form-left">
            <div class="content">
              <div class="info-box">
                <p>{{ $t('deposit.cc.header') }}</p>
                <DepositInfo :instData="instData" :noteData="noteData"></DepositInfo>
              </div>
              <div class="form_main">
                <p class="title">
                  {{ $t('deposit.method.form.header', { method: $t('deposit.creditOrDebit.header') }) }}
                </p>
                <el-form
                  label-position="top"
                  :model="bridgerPayForm"
                  ref="bridgerPayForm"
                  status-icon
                  :rules="bridgerPayRules"
                >
                  <div class="form-box">
                    <ul class="clearfix">
                      <li>
                        <AccountNumber
                          :unsupportedCurrenciesList="invalidCurrencies"
                          @setCurrency="setCurrency"
                          @setAccountNumber="setAccountNumber"
                        ></AccountNumber>
                      </li>
                      <li>
                        <el-form-item :label="setAmtLabel(accountCurrency, bridgerPayForm.amount)" prop="amount">
                          <numeric-input
                            v-model="bridgerPayForm.amount"
                            :currency="accountCurrency"
                            :precision="2"
                          ></numeric-input>
                        </el-form-item>
                      </li>
                    </ul>
                    <ul class="clearfix">
                      <li>
                        <el-form-item :label="$t('common.field.imptNotes')" prop="notes">
                          <el-input v-model="bridgerPayForm.notes" data-testid="imptNotes"></el-input>
                        </el-form-item>
                      </li>
                    </ul>
                  </div>
                  <el-form-item>
                    <el-button
                      class="btn-blue"
                      :loading="loading"
                      :disabled="loading"
                      @click="submitForm()"
                      data-testid="submit"
                    >
                      {{ $t('common.button.submit') }}
                    </el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <div class="form-right creditcard">
            <div class="logo cc cc2way"></div>
          </div>
        </div>
      </div>
    </div>
    <div id="main" v-show="loadTemplate"></div>
  </CreditCardTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import CreditCardTemplate from '@/components/template/deposit/CreditCardTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import mixin from '@/mixins/page/deposit';
import creditCard from '@/mixins/page/deposit/creditCard';
import { apiBridge_payment } from '@/resource';

export default {
  name: 'bridgerPay',
  components: { NumericInput, AccountNumber, CreditCardTemplate, DepositInfo },
  mixins: [mixin, creditCard],
  data() {
    // current requirement only validate minLimit, no maxLimit
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimitByCurrency) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: this.minLimitByCurrency,
              currency: this.accountCurrency
            })
          )
        );
      } else if (parseFloat(value) > this.maxLimitByCurrency) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', {
              maxLimit: this.maxLimitByCurrency,
              currency: this.accountCurrency
            })
          )
        );
      } else {
        callback();
      }
    };
    return {
      invalidCurrencies: [],
      loadTemplate: false,
      bridgerPayForm: {
        accountNumber: '',
        amount: '',
        notes: ''
      },
      bridgerPayRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        notes: [
          {
            pattern: /^.{0,256}$/,
            message: this.$t('common.formValidation.note256'),
            trigger: 'blur'
          }
        ]
      },
      bridgerPayPayments: { shopperResultUrl: '', jsUrl: '', show: false },
      instData: [
        this.$t('deposit.reminder.inst1'),
        this.$t('deposit.reminder.inst4'),
        this.$t('deposit.reminder.inst5'),
        this.$t('deposit.reminder.inst6')
      ],
      noteData: [
        this.$t('deposit.creditOrDebit.bridgerPay.note1'),
        this.$t('deposit.creditOrDebit.bridgerPay.note2', { mail: 'info@puprime.com' }),
        this.$t('deposit.creditOrDebit.bridgerPay.note3', {
          mail: 'info@puprime.com',
          href: 'https://www.puprime.com/'
        })
      ]
    };
  },
  computed: {
    countryCode() {
      return parseInt(this.$store.state.common.countryCode);
    }
  },
  computed: {
    minLimitByCurrency() {
      switch (this.accountCurrency) {
        case 'USC':
          return 5000;
        case 'HKD':
          return 400;
        case 'JPY':
          return 8000;
        default:
          return 50;
      }
    },
    maxLimitByCurrency() {
      switch (this.accountCurrency) {
        case 'USD':
          return 1000000;
        default:
          return 99999999.99;
      }
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.bridgerPayForm.accountNumber = accountNumber;
    },
    submitDeposit() {
      return apiBridge_payment(
        {
          mt4Account: this.bridgerPayForm.accountNumber,
          operateAmount: this.bridgerPayForm.amount,
          applicationNotes: this.bridgerPayForm.notes,
          paymentMethod: 'bridge'
        },
        this.token
      );
    },
    submitForm() {
      this.$refs['bridgerPayForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data && result.data.code == 0) {
                this.loadTemplate = true;
                this.showBridgerpay(result.data.data);
              } else {
                this.errorMessage(this.$t('deposit.default.failed'));
                // re-calling anti-reuse token
                this.fetchToken()
                  .then(resp => {
                    this.loading = false;
                  })
                  .catch(resp => {
                    this.loading = false;
                    this.errorMessage(this.$t('resetPassword.failed'));
                  });
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        }
      });
    },
    showBridgerpay(data) {
      const dataJson = JSON.parse(data);
      let script = document.createElement('script');
      script.setAttribute('src', dataJson.url);
      script.setAttribute('data-cashier-key', dataJson.key);
      script.setAttribute('data-theme', 'dark');
      script.setAttribute('data-cashier-token', dataJson.token);
      document.getElementById('main').appendChild(script);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
@import '@/assets/css/pages/deposit/ccDefault.scss';
</style>
